<template>
  <section class="w-full">
    <header-register
      :showButtonView="(previewButtonIsVisible = false)"
      :subTitleText="
        $t('sectionGroupPermission.permissionsGroupRegistrationSubTitle')
      "
      >{{ $t("sectionGroupPermission.permissionsGroupRegistrationTitle") }}
    </header-register>
    <main class="w-full mt-5">
      <ManageGroupPermissions />
    </main>
  </section>
</template>

<script type="module">
import HeaderRegister from "@core/components/register/HeaderRegister.vue";
import ManageGroupPermissions from "@/components/groupPermission/components/create/ManageGroupPermissions.vue";

export default {
  name: "GroupPermissionCreate",
  components: {
    HeaderRegister,
    ManageGroupPermissions,
  },
};
</script>
